import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiTooltip from "components/MuiTooltip/MuiTooltip";
import MDTypography from "components/MDTypography";
import { FLAVOUR2 } from "utils/constants";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import MDBox from "components/MDBox";
import { Tooltip } from "@mui/material";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import "./SidenavDialogBox.css";
import { collapseText } from "./styles/sidenavCollapse";
import { useMaterialUIController } from "context";

let dynamicUrl =
  FLAVOUR2 === "false"
    ? `https://${localStorage.getItem("username")}:********@${window.location.hostname}/wd/hub`
    : `${window.location.protocol}//${window.location.host}/wd/hub`;

let protocol = window.location.protocol === "https:" ? "wss://" : "ws://";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CopyCommand = ({ copyState, handleCopy }) => (
  <Tooltip title={copyState ? "Copied" : "Command Copy"} arrow placement="right">
    <div className="copyIcon" onClick={() => handleCopy()}>
      <CopyAllIcon color="black" />
    </div>
  </Tooltip>
);

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const SidenavDialogBox = () => {
  const [copyState, setCopyState] = React.useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const [open, setOpen] = React.useState(false);
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    //  sidenavColor
  } = controller;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const copyUrl = () => {
    setCopyState(true);
    if (FLAVOUR2 === "false") {
      const token = localStorage.getItem("automation_token");
      const username = localStorage.getItem("username");

      copyToClipboard(
        `https://${username}:${token}@${window.location.hostname}/wd/hub`
      );
    } else {
      copyToClipboard(`${window.location.protocol}//${window.location.host}/wd/hub`);
    }
    setTimeout(() => {
      setCopyState(false);
    }, 2000);
  };

  const copyPlaywrightUrl = () => {
    setCopyState(true);
    if (FLAVOUR2 === "false") {
      copyToClipboard(`${protocol}${window.location.host}/playwright`);
    } else {
      copyToClipboard(`${protocol}${window.location.host}/playwright`);
    }
    setTimeout(() => {
      setCopyState(false);
    }, 2000);
  };

  const unsecuredCopyToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed"; //avoid scrolling to bottom
    document.getElementById("dialogbox_container").appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.log("Was not possible to copy te text: ", err);
    }

    document.getElementById("dialogbox_container").removeChild(textArea);
  };

  const copyToClipboard = (content) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      unsecuredCopyToClipboard(content);
    }
  };

  return (
    <>
      <div className="webdriverURL" onClick={handleClickOpen}>
        <MDTypography
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
            })
          }
          fontSize="15px"
          color="#344767"
          fontWeight="bold"
        >
          Webdriver URLs
        </MDTypography>
        <RoomPreferencesIcon className="webdriver_copy_icon" />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "50%",
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <MDTypography fontSize="15px" color="#344767" fontWeight="bold">
            URLs for running Automations
          </MDTypography>
        </BootstrapDialogTitle>
        <DialogContent id="dialogbox_container" dividers className="command_body">
          <MDBox className="command_section">
            <MDTypography variant="h6" color="#344767">
              Selenium URL
            </MDTypography>
            <MDBox className="code_container">
              <Tooltip title={dynamicUrl} placement="top">
                <MDBox className="scroll_container">
                  <MDTypography variant="body2" color="black">
                    {dynamicUrl}
                  </MDTypography>
                </MDBox>
              </Tooltip>
              <CopyCommand copyState={copyState} handleCopy={copyUrl} />
            </MDBox>
          </MDBox>

          {/* playwright url */}
          <MDBox className="command_section">
            <MDTypography variant="h6" color="#344767">
              Playwright URL
            </MDTypography>
            <MDBox className="code_container">
              <Tooltip title={`${protocol}${window.location.host}/playwright`} placement="top">
                <MDBox className="scroll_container">
                  <MDTypography variant="body2" color="black">
                    {`${protocol}${window.location.host}/playwright`}
                  </MDTypography>
                </MDBox>
              </Tooltip>
              <CopyCommand copyState={copyState} handleCopy={copyPlaywrightUrl} />
            </MDBox>
          </MDBox>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default SidenavDialogBox;
